export {default as SectionOne} from './SectionOne'
export {default as SectionTwo} from './SectionTwo'
export {default as SectionThree} from './SectionThree'
export {default as SectionFour} from './SectionFour'
export {default as SectionFive} from './SectionFive'
export {default as SectionSix} from './SectionSix'
export {default as SectionSeven} from './SectionSeven'
export {default as CDS} from './CDS'
export {default as SPTA} from './SPTA'
export {default as SectionFtx} from './SectionFtx'
