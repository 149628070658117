import axios from 'axios'

export const postContactUsEmail = async (email, name,bankruptcyCase, message, subject, recaptchaToken) => {
  let response
  try {
    response = await axios.post('/api/contact-us', {email, name, bankruptcyCase, message, subject, recaptchaToken})
  } catch (err) {
    response = err
  }
  return response
}
